import React from 'react';
import { Layout, StaticSEO, CompactSectionStyles, CompactSectionContent, Box } from '@core';
import { useLocale } from '@modules';
import { FinniRevealCards } from '../components/composite/landing/FinniRevealCards';

const NotFoundPage = () => {
  const ref = React.useRef(null);
  const locale = useLocale();
  return (
    <Layout>
      <StaticSEO pageId='404' />
      <CompactSectionStyles>
        <CompactSectionContent titleAs='h1' id='404.init' buttonLink={`/${locale}`}>
          <FinniRevealCards image='cards.jpg' triggerRef={() => ref} />
        </CompactSectionContent>
      </CompactSectionStyles>
      <Box sx={{ height: 0, margin: 0, padding: 0 }}>
        <Box ref={ref} />
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
